import React from "react"
import styled from "styled-components"
import BackgroundPhotoUpload from "./BackgroundPhotoUpload"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import Chip from "@mui/material/Chip"
import { CircularProgress } from "@mui/material"
import { navigate } from "gatsby"
import { useAddUserPutMutation } from "./features/api/authApi"
import { useSelector } from "react-redux"

function EditProfileForm({ keyId, userProfile }) {
  const [file, setFile] = React.useState(null)
  const [file1, setFile1] = React.useState(null)
  const [skillText, setSkillText] = React.useState()
  const [scaleValue, setScaleValue] = React.useState("")
  const [error, setError] = React.useState("")

  const [profileDetails, setProfileDetails] = React.useState({
    college_name: userProfile[0]?.user_college_name,
    last: userProfile[0]?.userLastName,
    name: userProfile[0]?.userFirstName,
    degree: userProfile[0]?.user_degree,
    linkedin: userProfile[0]?.linkedin_url,
    pinterest: "",
    about_me: userProfile[0]?.userInfo,
    company_name: userProfile[0]?.company_name,
    company_year: userProfile[0]?.company_year,
    company_desc: userProfile[0]?.company_desc,
  })
  const [skillsArray, setSkillsArray] = React.useState(
    userProfile[0]?.user_skills ? userProfile[0]?.user_skills : []
  )

  const [userDetialsStatus, setUserDetailsStatus] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [preview, setPreview] = React.useState()
  const [preview1, setPreview1] = React.useState()

  const [uploadError, setUploadError] = React.useState("")
  const [imageUpload, setImageUpload] = React.useState("")
  const [imageUpload1, setImageUpload1] = React.useState("")

  //redux
  const token = useSelector((state) => state.user.token)
  const [addUserPut, { isLoading, isSuccess, status }] = useAddUserPutMutation()

  // const handleUpload = async (event) => {
  //   event.preventDefault()

  //   if (file && file["type"].includes("image")) {
  //     if (file.size > 5242880) {
  //       setError("File size is larger than 1MB!")
  //     } else {
  //       const formData = new FormData()
  //       formData.append("files", file)

  //       const res_upload = await axios({
  //         method: "POST",
  //         url: `${process.env.STRAPI_API_URL}/upload`,
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         data: formData,
  //       })
  //       if (res_upload.data[0].id && res_upload.status === 200) {
  //         setImageUploadStaus(true)
  //         const res = await fetch(
  //           `${process.env.STRAPI_API_URL}/userprofiles/${keyId}`,
  //           {
  //             method: "PUT",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token}`,
  //             },
  //             body: JSON.stringify({
  //               userBackgroundPhoto: res_upload.data[0].id,
  //             }),
  //           }
  //         )
  //         res && setUserDetailsStatus(true)
  //       }
  //     }
  //   } else {
  //     setError("This is not an image file!")
  //   }
  // }

  const handleSkills = (e) => {
    e.preventDefault()
    if (skillText === "" || scaleValue === "") {
      setError("one of the field is empty!")
    } else {
      if (scaleValue >= 10) {
        setError("value must lesser than 10")
      } else {
        setSkillsArray((oldArray) => [
          ...oldArray,
          { text: skillText, percentage: scaleValue },
        ])
        setSkillText("")
        setScaleValue("")
        setError("")
      }
    }
  }

  const handleDelete = (id) => {
    console.log(id)
    setSkillsArray((skill) => skill.filter((chip, i) => id !== i))
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setFile(null)
    setFile1(null)
    setScaleValue("")
    setSkillText("")
    setSkillsArray([])
    setProfileDetails({
      name: "",
      last: "",
      college_name: "",
      linkedin: "",
      pinterest: "",
      about_me: "",
      degree: "",
      company_name: "",
      company_year: "",
      company_desc: "",
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (imageUpload === "" && !userProfile[0]?.userPhoto) {
      setUploadError(`click on upload button.`)
      setLoading(false)
    } else {
      const kId = keyId
      const credentials = {
        userBackgroundPhoto: imageUpload1,
        userPhoto: imageUpload || userProfile[0]?.userPhoto.id,
        userFirstName: profileDetails.name,
        userLastName: profileDetails.last,
        linkedin_url: profileDetails.linkedin,
        userInfo: profileDetails.about_me,
        company_name: profileDetails.company_name,
        company_year: profileDetails.company_year,
        company_desc: profileDetails.company_desc,
        user_skills: skillsArray,
        user_college_name: profileDetails.college_name,
        user_degree: profileDetails.degree,
      }
      await addUserPut({ credentials, kId })
    }
  }

  React.useEffect(() => {
    isSuccess && (
      <>
        {setUserDetailsStatus(true)}
        {setUploadError("")}
      </>
    )
    isSuccess &&
      setTimeout(() => {
        window.location.href = "/dashboard"
      }, 500)
  }, [isSuccess])

  //   console.log(isSuccess, status, userDetialsStatus)

  console.log(skillsArray)
  return (
    <Wrapper>
      <div className="input_wrapper">
        <form onSubmit={handleSubmit}>
          <BackgroundPhotoUpload
            file={file}
            preview={preview}
            setFile={setFile}
            setPreview={setPreview}
            token={token}
            setImageUpload={setImageUpload}
            profile={false}
            label="Profile Photo"
            userProfile={userProfile}
          />
          <div className="container" id="personal">
            <div className="inside_container">
              <h4>first name</h4>
              <div className="input_container">
                <input
                  className="input"
                  type="text"
                  placeholder="first name"
                  value={profileDetails.name}
                  required
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="inside_container">
              <h4>last name</h4>

              <div className="input_container">
                <input
                  className="input"
                  type="text"
                  placeholder="last name"
                  required
                  value={profileDetails.last}
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      last: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="inside_container">
              <h4>university or college name</h4>

              <div className="input_container">
                <input
                  className="input"
                  type="text"
                  placeholder="university or college name"
                  value={profileDetails.college_name}
                  required
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      college_name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="inside_container">
              <h4>degree</h4>

              <div className="input_container">
                <input
                  className="input"
                  type="text"
                  placeholder="degree"
                  required
                  value={profileDetails.degree}
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      degree: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* <BackgroundPhotoUpload
            file={file1}
            preview={preview1}
            setFile={setFile1}
            setPreview={setPreview1}
            token={token}
            setImageUpload={setImageUpload1}
            profile={true}
            label="Background Photo"
            userProfile={userProfile}
          /> */}
          <h2 className="social_media_links">Social Media Links</h2>
          <div className="container">
            <div className="inside_container">
              <h4>linkedin</h4>

              <div className="input_container">
                <input
                  className="input"
                  type="text"
                  placeholder="url"
                  required
                  value={profileDetails.linkedin}
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      linkedin: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="inside_container">
              <h4>pinterest</h4>

              <div className="input_container">
                <input
                  className="input"
                  type="text"
                  placeholder="url"
                  value={profileDetails.pinterest}
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      pinterest: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="break_line" />

          <div className="about_me_text_input_wrapper" id="about">
            <div className="container">
              <div className="title_container">
                <h2 className="social_media_links">About me</h2>
                <span>
                  This information Will Be Displayed Publicly So Be Careful What
                  You Share
                </span>
              </div>
              <div className="text_input_container">
                <textarea
                  id="notesSave"
                  name="notes"
                  rows="8"
                  placeholder="type here.."
                  value={profileDetails.about_me}
                  required
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      about_me: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="break_line" />

          <div className="skill_area_wrapper" id="skills">
            <div className="container">
              <div className="title_container">
                <h2 className="social_media_links">skill + tools</h2>
                <span>
                  This information Will Be Displayed Publicly So Be Careful What
                  You Share
                </span>
                {skillsArray?.length ? (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: "10px 0",
                    }}
                  >
                    {skillsArray?.map((s, id) => (
                      <Chip label={s.text} onDelete={() => handleDelete(id)} />
                    ))}
                  </div>
                ) : null}
                {error ? (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {error}
                  </span>
                ) : null}
              </div>
              <div className="text_area_section">
                <div className="inside_container">
                  <div className="input_container">
                    <input
                      className="input"
                      type="text"
                      value={skillText}
                      placeholder="enter your skill or tools"
                      onChange={(e) => setSkillText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="inside_container">
                  <div className="input_container">
                    <input
                      className="input"
                      type="number"
                      min={0}
                      max={10}
                      value={scaleValue}
                      placeholder="rate your'e skills or tools(1 - 10)"
                      onChange={(e) => setScaleValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="skills_add_button_wrapper">
                  <button onClick={handleSkills}>
                    add {``} <AddOutlinedIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="break_line" />

          <div className="company_input_wrapper" id="experience">
            <div className="container">
              <div className="title_container">
                <h2 className="social_media_links">experience</h2>
                <span>
                  This information Will Be Displayed Publicly So Be Careful What
                  You Share
                </span>
              </div>
              <div className="text_area_section">
                <div className="inside_container">
                  <div className="input_container">
                    <input
                      className="input"
                      type="text"
                      value={profileDetails.company_name}
                      placeholder="company name"
                      onChange={(e) =>
                        setProfileDetails({
                          ...profileDetails,
                          company_name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="inside_container">
                  <div className="input_container">
                    <input
                      className="input"
                      type="number"
                      value={profileDetails.company_year}
                      placeholder="year"
                      onChange={(e) =>
                        setProfileDetails({
                          ...profileDetails,
                          company_year: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="text_input_container">
                <textarea
                  id="notesSave"
                  name="notes"
                  rows="8"
                  placeholder="type here.."
                  value={profileDetails.company_desc}
                  required
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      company_desc: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="profile_form">
            <span className="upload_error">{uploadError}</span>
            <div className="profile_form_button_wrapper">
              {!isLoading && !userDetialsStatus && (
                <button
                  className="save_button"
                  type="submit"
                  disabled={userDetialsStatus}
                >
                  {!userDetialsStatus ? `save` : `saved`}
                </button>
              )}

              {isLoading && !userDetialsStatus && uploadError === "" && (
                <CircularProgress className="circular_progress" />
              )}
              {userDetialsStatus && (
                <button className="upload_disabled" disabled>
                  saved
                </button>
              )}
              <button className="cancel_button" onClick={handleCancel}>
                cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  )
}

export default EditProfileForm

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  button:disabled {
    padding: 20px;
    border-radius: 50px;
  }
  .input_wrapper {
    width: 100%;
    .profile_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .upload_error {
        font-size: 16px;
        color: red;
        padding-top: 20px;
        margin-top: 20px;
      }
    }
    .profile_form_button_wrapper {
      display: flex;
      margin-top: 3rem;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      .save_button,
      .cancel_button {
        min-width: 150px;
        padding: 15px 20px;
        display: flex;
        justify-content: center;
        background: var(--purpleColor);
        color: #ffffff;
        border: 1px solid var(--purpleColor);
        border-radius: 50px;
        margin-right: 10px;
        cursor: pointer;
        outline: none;
        @media (max-width: 767px) {
          min-width: 0;
        }
      }
      .upload_disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        border-radius: 31px;
        padding: 10px 15px;
      }
      .cancel_button {
        background: none;
        border: none;
        color: #000;
        padding: 0;
        min-width: 80px;
      }
    }
    .break_line {
      width: 50%;
      height: 2px;
      background: radial-gradient(
        100.86% 10936973.14% at 100% 0%,
        #000000 0%,
        #000000 0%,
        rgba(0, 0, 0, 0) 0.01%,
        rgba(0, 0, 0, 0.19) 45.67%,
        rgba(0, 0, 0, 0) 100%
      );
      margin: 2rem 0;
    }
    .title_container {
      display: flex;
      flex-direction: column;
    }

    .skill_area_wrapper,
    .company_input_wrapper {
      display: flex;
      margin-top: 1.5rem;
      .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0;
      }
      .text_area_section {
        display: flex;
        margin-top: 1.5rem;
        align-items: center;
        @media (max-width: 991px) {
          flex-direction: column;
          align-items: unset;
          margin-top: 0 !important;
        }
      }
    }
    .company_input_wrapper {
      .text_area_section {
        margin-top: 1.5rem;
      }
    }
    .about_me_text_input_wrapper {
      .container {
        display: flex;
        flex-direction: column;
      }
    }
    .social_media_links {
      font-weight: 600;
      font-size: 24px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      margin-bottom: 0;
      color: #000000 !important;
    }
    span {
      font-weight: normal;
      font-size: 14px;

      letter-spacing: 0.02em;
      text-transform: capitalize;

      color: #000000;
    }
    h4 {
      font-weight: 500;
      font-size: 14px;
      /* identical to box height */
      margin: 0;
      margin-bottom: 5px;
      letter-spacing: 0.01em;
      text-transform: capitalize;

      color: #000000;
    }

    .circular_progress {
      width: 30px;
      height: 30px;
    }
    .container {
      display: flex;
      margin-top: 2rem;

      @media (max-width: 991px) {
        flex-wrap: wrap;
        margin-top: 1rem;
      }
      .input,
      textarea {
        width: 100%;
        padding: 20px 10px;
        border: none;
        background-color: transparent;
        transition: transform 250ms ease-in-out;
        font-size: 14px;
        line-height: 18px;
        outline: none;
        color: var(--secondaryColor);
        font-family: var(--family);
        backface-visibility: hidden;
      }
      .skills_add_button_wrapper {
        display: flex;
        @media (max-width: 991px) {
          margin-top: 1rem;
        }

        button {
          background: var(--purpleColor);
          outline: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 9px 20px;
          color: #fff;
          border-radius: 30px;
        }
      }
      .inside_container {
        width: 30%;
        margin-right: 2rem;
        @media (max-width: 1200px) {
          width: 50%;
        }
        @media (max-width: 991px) {
          width: 70%;
          margin-top: 1rem;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .input_container,
      .text_input_container {
        display: flex;
        flex-direction: row;
        background: #f8f8f8;
        border-radius: 5px;

        .search_icon {
          display: flex;
          width: 60px;
          background-color: none;
          justify-content: center;
          align-items: center;
          border-radius: 69.3284px;

          svg {
            color: #000;
            font-size: 30px;
          }
        }
        .input::placeholder {
          color: rgba(87, 87, 86, 0.8) !important;
          text-transform: lowercase;
          letter-spacing: 1.5px;
        }
      }
      .text_input_container {
        width: 50%;
        margin-top: 1.5rem;
        @media (max-width: 991px) {
          width: 100%;
        }
        textarea {
          width: 100%;
          resize: none;
          padding: 10px;
        }
        textarea::placeholder {
          color: rgba(87, 87, 86, 0.8) !important;
          text-transform: lowercase;
          letter-spacing: 1.5px;
        }
      }
    }

    .file_upload_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: auto;
      margin-top: 1.5rem;
      .file_upload {
        display: flex;
        justify-content: center;
        align-items: center;

        .background_photo {
          width: 150px;
          height: 150px;
          object-fit: cover;
        }
        .profile_photo {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 50%;
        }
        .upload_icon {
          position: absolute;
          z-index: -1;
        }
        .upload_button {
          display: flex;
          justify-content: space-around;
          margin-left: 2rem;
          flex-wrap: wrap;
          .upload_disabled {
            border: 1px solid #999999;
            background-color: #cccccc;
            color: #666666;
            border-radius: 31px;
            padding: 10px 15px;
          }
          .remove,
          .upload {
            border: 1px solid #151515;
            padding: 10px 15px;
            color: var(--secondaryColor);
            outline: none;
            cursor: pointer;
            background: #f8f8f8;
            border-radius: 31px;
            min-width: 100px;
            font-weight: 500;
            font-size: 12px;
          }
          .remove {
            background: none;
            border: none;
          }
        }
      }
    }

    .custom_file_input,
    .custom_file_profile_input {
      color: transparent !important;
      width: 150px;
      height: 150px;
      background: #f8f8f8;
      position: relative;
      opacity: 0.7;
      cursor: pointer;
    }
    input[type="file"]::file-selector-button {
      visibility: hidden;
    }

    .custom_file_profile_input {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .custom_file_input::-webkit-file-upload-button {
      visibility: hidden;
    }
    .custom_file_profile_input::-webkit-file-upload-button {
      visibility: hidden;
    }
    /* .custom_file_input::before {
      color: black;
      display: inline-block;
      background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
      border: 1px solid #999;
      border-radius: 3px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      text-shadow: 1px 1px #fff;
      font-weight: 700;
      font-size: 10pt;
    } */
    .custom_file_input:hover::before {
      border-color: black;
    }
    .custom_file_input:active {
      outline: 0;
    }
    .custom_file_input:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }
  }
`
