import React from "react"
import styled from "styled-components"
import Analogo from "../images/favicon.svg"
import { Link } from "gatsby"
import EditProfileForm from "./EditProfileForm"
import AvatarMenu from "./Dashboard/AvatarMenu"
import axios from "axios"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useSelector } from "react-redux"
import { useGetUserDetailsQuery, useGetUserQuery } from "./features/api/authApi"

function Index(props) {
  const [user, setUser] = React.useState("")
  const [userEmail, setUserEmail] = React.useState("")
  const [userId, setUserId] = React.useState(null)
  const [value, setValue] = React.useState(null)

  //redux
  const token = useSelector((state) => state.user.token)
  const { data: userProfile, isLoading, error } = useGetUserQuery()
  const {
    data: userDetails,
    isLoading: userDetailsIsLoading,
    error: userDetailsError,
    isError: userDetailsIsError,
    isSuccess: userDetailsIsSuccess,
  } = useGetUserDetailsQuery()
  //end redux

  const [loading, setLoading] = React.useState(true)

  //   React.useEffect(() => {
  //     const fetchUserInfo = async () => {
  //       await axios
  //         .get(`${process.env.STRAPI_API_URL}/users/me`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         })
  //         .then((res) => {
  //           setUser(res.data.username)
  //           setUserEmail(res.data.email)
  //           setUserId(res.data._id)
  //           setLoading(false)
  //         })
  //         .catch((err) => {})
  //     }

  //     fetchUserInfo()
  //   }, [token])

  const removeToken = () => {
    sessionStorage.removeItem("token")
    localStorage.removeItem("details")
    localStorage.removeItem("topic")
    localStorage.removeItem("notes")

    setTimeout(() => {
      window.location.href = "/"
    }, 1000)
  }
  const handleScroll = (val) => {
    scrollTo(`#${val}`)
    setValue(val)
  }

  return (
    <>
      {!userDetailsIsLoading && !isLoading && (
        <Container>
          <div className="profile_edit_wrapper">
            <div className="dashboard_logo_wrapper">
              <div className="dashboard_logo">
                <Link to="/">
                  <img src={Analogo} alt="certisured logo" />
                </Link>
              </div>
            </div>

            <div className="avatar_menu_wrapper">
              <AvatarMenu
                user={userDetails.username}
                email={userDetails.email}
                removeToken={removeToken}
                userProfileId={userProfile}
              />
            </div>

            <div className="profile_menu_wrapper">
              <div>
                <Link to="/dashboard">dashboard</Link>
              </div>
              <div className="profile_menu">
                <div style={{ position: "sticky", top: "0" }}>
                  <h2 className="profile_menu_h2">Settings</h2>
                  <div className="settings_mobile_menu">
                    <ul>
                      <li
                        onClick={() => handleScroll("personal")}
                        aria-hidden="true"
                        style={
                          value === "personal"
                            ? { color: "var(--purpleColor)" }
                            : null
                        }
                      >
                        personal details
                      </li>
                      <li
                        onClick={() => handleScroll("about")}
                        aria-hidden="true"
                        style={
                          value === "about"
                            ? { color: "var(--purpleColor)" }
                            : null
                        }
                      >
                        about me
                      </li>
                      <li
                        onClick={() => handleScroll("skills")}
                        aria-hidden="true"
                        style={
                          value === "skills"
                            ? { color: "var(--purpleColor)" }
                            : null
                        }
                      >
                        skills + tools
                      </li>
                      <li
                        onClick={() => handleScroll("experience")}
                        aria-hidden="true"
                        style={
                          value === "experience"
                            ? { color: "var(--purpleColor)" }
                            : null
                        }
                      >
                        experience
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_edit_wrapper_section">
              <div className="profile_edit_section_section">
                <div className="details">
                  <h3>Personal Details</h3>
                  <span>
                    This information Will Be Displayed Publicly So Be Careful
                    What You Share
                  </span>
                </div>
                <div className="form_wrapper">
                  <EditProfileForm
                    keyId={userProfile[0]?.id}
                    userProfile={userProfile}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}

      {!userDetails && (
        <Wrapper>
          <h2>Loading...</h2>
        </Wrapper>
      )}
    </>
  )
}

export default Index

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
  padding: 0 2rem;
`
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  width: 100%;
  height: auto;
  margin-top: 2rem;
  .profile_edit_wrapper {
    grid-area: auto/1/auto/12;
    width: 100%;
    display: grid;
    grid-template-columns: 100px 1fr 5fr 100px;
    grid-template-rows: auto;

    @media (max-width: 991px) {
      grid-template-columns: 70px 2fr 5fr 70px;
    }
    @media (max-width: 767px) {
      grid-template-columns: 20px 50px 1fr 50px 20px;
    }
    .login {
      background-color: var(--thirdColor);
      text-decoration: none;
      min-width: 150px;
      padding: 15px 20px;
      color: var(--secondaryColor);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      font-weight: 500;
    }
    .header_wrapper {
      grid-area: auto/2/auto/4;
    }
    .dashboard_logo_wrapper {
      grid-area: auto/1/auto/2;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 767px) {
        grid-area: auto/2/auto/3;
      }
      .dashboard_logo {
        display: block;
        a {
          text-decoration: none;
        }
        img {
          width: 50px !important;

          @media (max-width: 991px) {
            width: 50px !important;
          }
        }
      }
    }
    .avatar_menu_wrapper {
      grid-area: auto/4/auto/5;
    }
    .profile_menu_wrapper {
      grid-area: auto/2/auto/3;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 40px;
      @media (max-width: 767px) {
        margin-right: 0;
        align-items: unset;
        margin-top: 1.5rem;
      }
      @media (max-width: 767px) {
        grid-area: auto/2/auto/5;
      }
      a {
        font-weight: 600;
        font-size: 17.88px;
        color: #000000;
        text-decoration: none;
        position: relative;
        transition: 0.2s ease-in-out;
        @media (max-width: 767px) {
          font-size: 14px;
          margin-left: 20px;
        }
      }

      a::before {
        content: "";
        position: absolute;
        top: 0;
        left: -15px;
        bottom: 0;
        width: 20px;
        height: 20px;
        background-color: transparent;
        transform: rotate(-45deg);
        border: 1px aliceblue;
        border-left: 1px solid black;
        border-top: 1px solid black;
        @media (max-width: 767px) {
          width: 15px;
          height: 15px;
        }
      }
      a:hover {
        transform: scale(1.1);
      }

      .settings_mobile_menu {
        @media (max-width: 767px) {
          display: flex;
        }
      }
      .profile_menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        border-right: 1px solid #15151533;
        @media (max-width: 767px) {
          border-right: 0;
          align-items: unset;
        }

        .profile_menu_h2 {
          font-weight: bold;
          font-size: 32px;
          margin-bottom: 5px;
          /* identical to box height */
          letter-spacing: 0.02em;
          color: #002f54;
          @media (max-width: 767px) {
            font-size: 22px;
          }
        }
        ul {
          margin: 0;
          padding: 0;
          @media (max-width: 767px) {
            display: flex;
            overflow-y: auto;
            &::-webkit-scrollbar {
              height: 1px;
            }
          }
        }
        li {
          list-style: none;
          margin: 10px 0;
          padding: 5px 0;
          font-weight: 600;
          font-size: 16px;

          /* identical to box height */
          letter-spacing: 0.02em;
          text-transform: lowercase;
          transition: all 0.2s ease;
          color: #000000;
          cursor: pointer;
          opacity: 0.6;
          outline: none;
          @media (max-width: 767px) {
            font-size: 14px;
            margin-right: 20px;
            white-space: nowrap;
          }
        }
        li:hover {
          color: var(--purpleColor);
          transform: scale(1.02);
        }
        li:active {
          color: var(--purpleColor);
        }
      }
    }
    .profile_edit_wrapper_section {
      grid-area: auto/3/auto/4;
      width: 100%;

      padding-right: 2rem;
      padding-left: 2rem;
      margin-left: 20px;
      @media (max-width: 767px) {
        grid-area: auto/2/auto/5;

        padding-right: 0;
        padding-left: 0;
        margin-left: 0;
      }
      .profile_edit_section_section {
        display: flex;
        flex-direction: column;
        width: 100%;

        .details {
          display: flex;
          flex-direction: column;
          margin-top: 50px;
          @media (max-width: 767px) {
            margin-top: 20px;
          }
          h3 {
            font-weight: 600;
            font-size: 24px;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            margin: 0;
            margin-bottom: 5px;
            color: #000000;
            @media (max-width: 767px) {
              font-size: 20px;
            }
          }
          span {
            font-weight: normal;
            font-size: 14px;

            letter-spacing: 0.02em;
            text-transform: capitalize;

            color: #000000;
            @media (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
`
