import React from "react"

const Progress_bar = ({ bgcolor, progress, maxCompleted, height, span }) => {
  const percent = (progress / maxCompleted) * 100
  const Parentdiv = {
    width: "100%",
    backgroundColor: "#15151552",
    borderRadius: 40,
    height: `${height}px`,
  }

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 6,
    textAlign: "right",
    display: "flex",
    alignItems: "center",
  }

  const progresstext = {
    padding: 10,
    color: "black",
    fontWeight: 600,
    fontSize: "12px",
  }

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>
          {span ? `${Math.round(percent)}%` : null}
        </span>
      </div>
    </div>
  )
}

export default Progress_bar
