import React from "react"
import styled from "styled-components"
import Analogo from "../images/favicon.svg"
import { Link } from "gatsby"
import back from "../images/Rectangle 1.png"
// import { UserContext } from "./UseToken"
import linkedin from "../images/linkedin.svg"
import ReactMarkdown from "react-markdown"
import Progress_bar from "./SkillProgressBar"
import { useGetUserQuery } from "./features/api/authApi"

function Profile() {
  const { data: userProfile, isLoading } = useGetUserQuery()

  return userProfile && userProfile.length && userProfile[0]?.userPhoto ? (
    <Wrapper>
      <div className="wrapper_wrapper">
        <div className="background_wrapper">
          <div className="profile_navbar_logo">
            <div className="navbar_logo">
              <Link to="/">
                <img src={Analogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="link_to_dashboard">
            <Link to="/dashboard">dashboard</Link>
          </div>
          <div className="user_info_wrapper">
            <div className="user_first_name">
              <h2>{userProfile[0]?.userFirstName}</h2>
            </div>
            <div className="user_last_name">
              <h2>{userProfile[0]?.userLastName}</h2>
            </div>
            <div className="user_college_name">
              <ReactMarkdown>{userProfile[0]?.user_college_name}</ReactMarkdown>
            </div>
            <div className="user_social_links">
              <a href={userProfile[0]?.linkedin_url}>
                <img src={linkedin} alt={userProfile[0]?.linkedin_url} />
              </a>
            </div>
            <div className="edit_profile">
              <Link to="/app/settings">edit portfolio</Link>
            </div>
          </div>
        </div>
        <div className="user_bio_wrapper">
          <div className="about_me">
            <h4>about me</h4>
            <p>{userProfile[0]?.userInfo}</p>
          </div>
          <div className="about_skills">
            <h4>skills + tools</h4>
            <div className="skill_progress_wrapper">
              {userProfile[0]?.user_skills.map((u) => (
                <div className="progress">
                  <span>{u.text}</span>
                  <Progress_bar
                    maxCompleted={10}
                    progress={`${u.percentage}0`}
                    height={10}
                    bgcolor={`var(--purpleColor)`}
                    span={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="about_experience">
            <h4>experience</h4>
            <div className="experience_section_div">
              <h2>{userProfile[0]?.company_name}</h2>
              <sapn>{userProfile[0]?.company_year}</sapn>
              <ReactMarkdown>{userProfile[0]?.company_desc}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  ) : (
    <Container>
      <h1>You need to create an profile.</h1>
      <Link to="/app/settings">create profile</Link>
    </Container>
  )
}

export default Profile

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
  .wrapper_wrapper {
    grid-area: auto/1/auto/12;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    width: 100%;
    position: relative;
    grid-row-gap: 2rem;
    .user_bio_wrapper {
      grid-area: auto/1/auto/12;
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      width: 100%;
      h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.02em;
        color: var(--secondaryColor);
      }
    }
    .about_me {
      grid-area: auto/3/auto/10;
      width: 100%;
      @media (max-width: 991px) {
        grid-area: auto/2/auto/11;
      }
      p {
        font-weight: normal;
        font-size: 18px;

        letter-spacing: 0.02em;

        color: #000000;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
    .about_skills {
      grid-area: auto/3/auto/10;
      width: 100%;
      @media (max-width: 991px) {
        grid-area: auto/2/auto/11;
      }
      .skill_progress_wrapper {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        background-color: #f8f8f8;
        justify-content: space-evenly;
        padding: 25px 0;
        @media (max-width: 767px) {
          width: 100%;
        }
        .progress {
          width: 40%;
          margin-right: 0;
          margin-top: 1.2rem;
          margin-bottom: 1.2rem;

          @media (max-width: 767px) {
            margin-right: 0;
          }
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            letter-spacing: 0.02em;

            color: #000000;
          }
        }
      }
    }
    .about_experience {
      grid-area: auto/3/auto/10;
      @media (max-width: 991px) {
        grid-area: auto/2/auto/11;
      }
      .experience_section_div {
        h2 {
          font-weight: 600;
          font-size: 22px;

          letter-spacing: 0.02em;
          text-transform: uppercase;

          color: #151515 !important;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          /* identical to box height */

          letter-spacing: 0.02em;

          color: #000000;
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
        p {
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          /* or 133% */

          letter-spacing: 0.02em;

          color: #000000;
        }
      }
    }
  }

  .background_wrapper {
    grid-area: auto/1/auto/12;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: minmax(100px, max-content) minmax(100px, max-content) auto;
    background-color: #151515;
    height: 600px;
    background-size: cover !important;
    background-position: center;
  }
  .link_to_dashboard {
    grid-area: auto/3/auto/10;
    @media (max-width: 991px) {
      grid-area: auto/2/auto/10;
      display: flex;
      align-items: center;
    }
    a {
      font-weight: 600;
      font-size: 17.88px;
      color: #f8f8f8;
      text-decoration: none;
      position: relative;
      transition: 0.2s ease-in-out;
      @media (max-width: 767px) {
        font-size: 14px;
        margin-left: 20px;
      }
    }

    a::before {
      content: "";
      position: absolute;
      top: 0;
      left: -15px;
      bottom: 0;
      width: 20px;
      height: 20px;
      background-color: transparent;
      transform: rotate(-45deg);
      border: 1px aliceblue;
      border-left: 1px solid #f8f8f8;
      border-top: 1px solid #f8f8f8;
      @media (max-width: 767px) {
        width: 15px;
        height: 15px;
      }
    }
    a:hover {
      font-weight: 500;
    }
  }

  .user_info_wrapper {
    grid-area: auto/3/auto/10;
    @media (max-width: 991px) {
      grid-area: auto/2/auto/10;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    .user_first_name,
    .user_last_name {
      h2 {
        font-weight: bold;
        font-size: 58px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin: 0;
        color: #f8f8f8 !important;
        @media (max-width: 767px) {
          font-size: 32px;
        }
      }
    }
    .user_college_name {
      p {
        font-weight: 500;
        font-size: 18px;
        margin-top: 0;
        letter-spacing: 0.02em;

        color: #f8f8f8;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
    .user_social_links {
      img {
        width: 35px;
        transition: 0.2s ease-in-out;
      }
      a {
        outline: none;
        text-decoration: none;
      }
      a:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
    .edit_profile {
      margin-top: 10px;
      a {
        text-decoration: none;
        color: #f8f8f8;
        transition: 0.3s ease-in-out;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
  .profile_navbar_logo {
    grid-area: auto/2/auto/11;
    margin-top: 1.5rem;
    .navbar_logo {
      display: flex;
      img {
        width: 70px !important;
        @media (max-width: 767px) {
          width: 50px !important;
        }
      }
    }
  }
`
