import React from "react"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const token = useSelector((state) => state.user.token)
  if (!token) {
    navigate("/login")
    return null
  }

  return <Component {...rest} />
}
export default PrivateRoute
