import React from "react"
import styled from "styled-components"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"
import axios from "axios"
import CircularProgress from "@mui/material/CircularProgress"

function BackgroundPhotoUpload({
  setFile,
  token,
  preview,
  file,
  setPreview,
  setImageUpload,
  profile,
  label,
  userProfile,
}) {
  const [imageUploadStatus, setImageUploadStaus] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState("")

  React.useEffect(() => {
    if (!file) {
      userProfile[0]?.userPhoto && !profile && (
        <>{setPreview(userProfile[0]?.userPhoto.url)}</>
      )
      userProfile[0]?.userBackgroundPhoto && profile && (
        <>{setPreview(userProfile[0]?.userBackgroundPhoto.url)}</>
      )
      return
    }
    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

  const handleUpload = async (e) => {
    e.preventDefault()
    setLoading(true)
    const fileType = file["type"]

    const validImageTypes = ["image/jpeg", "image/png"]

    if (file && validImageTypes.includes(fileType)) {
      if (file.size > 5242880) {
        setError("File size is larger than 1MB!")
        setLoading(false)
      } else {
        const formData = new FormData()
        formData.append("files", file)
        const res_upload = await axios({
          method: "POST",
          url: `${process.env.STRAPI_API_URL}/upload`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: formData,
        })
        setImageUpload(res_upload.data[0].id)
        res_upload && setImageUploadStaus(true)
        res_upload && setLoading(false)
        // if (res_upload.data[0].id && res_upload.status === 200) {
        //   setImageUploadStaus(true)
        //   const res = await fetch(
        //     `${process.env.STRAPI_API_URL}/userprofiles/${keyId}`,
        //     {
        //       method: "PUT",
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${token}`,
        //       },
        //       body: JSON.stringify({
        //         userBackgroundPhoto: res_upload.data[0].id,
        //       }),
        //     }
        //   )
        //   res && setUserDetailsStatus(true)
        // }
      }
    } else {
      setError("This is not an image file!. Add only png or jpeg file")
      setLoading(false)
    }
  }
  const handleRemoveFile = (e) => {
    e.preventDefault()
    setFile(null)
    setError("")
    setPreview(undefined)
  }

  return (
    <div className="file_upload_wrapper">
      <h4>{label}</h4>
      <span
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "red",
          marginBottom: "8px",
        }}
      >
        {error}
      </span>
      <div className="file_upload">
        {!preview && <CameraAltOutlinedIcon className="upload_icon" />}
        {profile
          ? preview && <img src={preview} className="background_photo" alt="" />
          : preview && <img src={preview} className="profile_photo" alt="" />}
        {!preview ? (
          profile ? (
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              className="custom_file_input"
            ></input>
          ) : (
            <input
              type="file"
              required
              onChange={(e) => setFile(e.target.files[0])}
              className="custom_file_profile_input"
            ></input>
          )
        ) : null}
        {preview && (
          <div className="upload_button">
            {(!preview || file) && !loading && !imageUploadStatus && (
              <button className="upload" onClick={(e) => handleUpload(e)}>
                upload
              </button>
            )}

            {loading && !imageUploadStatus && (
              <CircularProgress className="circular_progress" />
            )}
            {imageUploadStatus && (
              <button className="upload_disabled" disabled>
                uploaded
              </button>
            )}

            <button className="remove" onClick={(e) => handleRemoveFile(e)}>
              remove
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default BackgroundPhotoUpload

const Wrapper = styled.div``
