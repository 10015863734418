import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/PrivateRoute"
import Settings from "../components/Settings"
import Profile from "../components/Profile"

const App = () => (
  <Router>
    <PrivateRoute path="/app/settings/" component={Settings} />
    <PrivateRoute path="/app/profile/" component={Profile} />
  </Router>
)
export default App
